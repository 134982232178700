// import "./App.css";
import Iconic from "./images/iconic.jpg";
import About1 from "./images/about1.png";
import About2 from "./images/about2.png";
import About3 from "./images/about3.png";
import About4 from "./images/about4.png";

function App() {
  return (
    <div>
    <div className="container d-flex flex-column gap-5 pb-5">
      <nav className="navbar mt-5">
        <div
          className="navbar-header d-flex gap-3"
        >
          <img style={{width:"100px"}}  src="./logo.jpeg" alt="Murarka group" />
          <p className="h1 fw-bold lh-lg">Murarka Group</p>
        </div>
        {/* <ul className="nav navbar-nav">
                    <li className="active"><a href="#">Home</a></li>
                    <li><a href="#">About Us</a></li>
                    <li><a href="#">Page 2</a></li>
                    <li><a href="#">Page 3</a></li>
                </ul> */}
      </nav>
      <div className="row justify-content-between">
        <div className="col-sm-12 col-md-6 col-lg-6 pe-5 d-flex flex-column justify-content-center">
          <p className="display-1 fw-bold lh-sm">Shaping <br/>India's <br/> tomorrow</p>
          <p className="lh-lg">
            Murarka Group is one of the largest group of
            central India, Nagpur
          </p>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6">
          <img className="w-75 rounded-4" src={Iconic} alt="Bulding Image" />
        </div>
      </div>
      <div className="row justify-content-between my-5" id="about">
        <div className="col-sm-12 col-md-5 col-lg-5 d-flex flex-column gap-4">
          <div className="row ">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <img
                className="w-100 rounded-2"
                src={About1}
                alt="photo galary image"
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <img
                className="w-100 rounded-2"
                src={About2}
                alt="photo galary image"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <img
                className="w-100 rounded-2"
                src={About3}
                alt="photo galary image"
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <img
                className="w-100 rounded-2"
                src={About4}
                alt="photo galary image"
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 me-5">
          <p className="display-5 fw-bold lh-sm">About us</p>
          <p className="lh-lg">The Murarka Group is a visionary company with over three and half decades of market 
          leadership in the printing and real estate industries. Founded by the <strong>Late Shri. Murlidharji Murarka and 
          Late Shri. Ramkisanji Murarka,</strong> and now being led by <strong>Mr. Shankar Murarka, Mr. Kishore Murarka and Mr.
          Brajesh Murarka,</strong> the Murarka Group has emerged as a prestigious name in Nagpur's Real Estate Landscape.
          </p>
          <p className="lh-lg">
          The Group stands at the forefront of the real estate industry, introducing opulent residential and commercial 
          spaces and redefining Nagpur's luxury living.
          Their remarkable portfolio boasts a multitude of exceptional projects in and around the city.
          </p>
          <p className="lh-lg">
          Setting a high standard of Quality and timely delivery, with a strong commitment to customer
          satisfaction, the group believes in providing exceptional client service and fulfilling their expectations.
          </p>
     
        </div>
      </div>
      <div className="row">
        <p className="display-5 fw-bold lh-sm">Manufacturing</p>
      </div>
      <div className="row">
        <p className="lh-lg">
          Manufacturing is the core of Murarka Group. The organisation came into
          existence because of its success in the manufacturing and processing
          sectors. The organisation is a key stakeholder in the the
          manufacturing sector of Nepal. We grew from selling rice locally to
          exporting refined palm oil to India.
        </p>
      </div>
      <div className="row justify-content-between">
        <div className="col-sm-12 col-md-3 col-lg-3">
          <img
            className="w-100 rounded-circle opacity-75"
            src={About1}
            alt="photo galary image"
          />
          {/* <h3>Cement</h3> */}
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3">
          <img
            className="w-100 rounded-circle opacity-75"
            src={About1}
            alt="photo galary image"
          />
          {/* <h3>Steel</h3> */}
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3">
          <img
            className="w-100 rounded-circle opacity-75"
            src={About1}
            alt="photo galary image"
          />
          {/* <h3>Refined oil</h3> */}
        </div>
      </div>
      <div className="row">
        <p className="display-5 fw-bold lh-sm">Trading</p>
      </div>
      <div className="row">
        <p className="lh-lg">
          Murarka Group has a long history of being involved in trade. What
          began as exporting rice in the days of inception, we have shifted our
          focus to bring globally recognised names to Nepal.
        </p>
      </div>
      <div className="row justify-content-between">
        <div className="col-sm-12 col-md-3 col-lg-3">
          <img
            className="w-100 rounded-circle opacity-75"
            src={About1}
            alt="photo galary image"
          />
          {/* <h3>Cement</h3> */}
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3">
          <img
            className="w-100 rounded-circle opacity-75"
            src={About1}
            alt="photo galary image"
          />
          {/* <h3>Steel</h3> */}
        </div>
      </div>
      <div className="row">
        <p className="display-5 fw-bold lh-sm">Services</p>
      </div>
      <div className="row">
        <p className="lh-lg">
          Moving from manufacturing to the service industry is a sign of
          progress. The service industry in Nepal is still young, and we at
          Murarka Group work to bring new instruments and products into the
          service industry through banking, insurance, and education.
        </p>
      </div>
      <div className="row justify-content-between">
        <div className="col-sm-12 col-md-3 col-lg-3">
          <img
            className="w-100 rounded-circle opacity-75"
            src={About1}
            alt="photo galary image"
          />
          {/* <h3>Cement</h3> */}
        </div>
        <div className="col-sm-12 col-md-3 col-lg-3">
          <img
            className="w-100 rounded-circle opacity-75"
            src={About1}
            alt="photo galary image"
          />
          {/* <h3>Steel</h3> */}
        </div>
      </div>

      
    </div>

    <div className="container-fluid p-5 bg-dark text-white">
      <div className="row justify-content-between">
        <div className="col-sm-12 col-md-2 col-lg-2">
          <div className="row h4 fw-bold">Murarka Group</div>
          <div className="row lh-lg">Anam Nagar, <br/>New Baneshwor <br/>Kathmandu, Nepal</div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4">
          <div className="list-group list-group-horizontal">
            <a href="#about" 
            className="list-group-item list-group-item-action border-0 bg-dark text-white">
              About
            </a>
            <a
              href="#manufacturing"
              className="list-group-item list-group-item-action border-0 bg-dark text-white"
            >
              Manufacturing
            </a>
            <a
              href="#trading"
              className="list-group-item list-group-item-action border-0 bg-dark text-white"
            >
              Trading
            </a>
            <a
              href="#services"
              className="list-group-item list-group-item-action border-0 bg-dark text-white"
            >
              Services
            </a>
          </div>
        </div>
      </div>
      </div>
      <div id="consent-banner"></div>
      <div id="teconsent">
        <script type="text/javascript" async="async" src="https://consent.trustarc.com/v2/notice/zlxm7w"></script>
      </div>
    </div>
  );
}

export default App;
